.b-contain *,
.b-contain *::before,
.b-contain *::after {
    box-sizing: content-box !important;
}

.b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.b-contain span {
    line-height: 0.44;
    font-size: 1rem;
    font-family: inherit;
}

.b-contain {
    display: table;
    position: relative;
    padding-left: 14px;
    cursor: pointer;
    margin-bottom: 0.4rem;
}

.b-contain input[type="checkbox"]~.b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    /* background: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255,255,255,0.3); */
    background: transparent;
    border: 1px solid rgba(112, 112, 112, 0.3);

    border-radius: 5px;
}

.b-contain input[type="checkbox"]~.b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 0.2rem;
    height: 0.5rem;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.b-contain input[type="checkbox"]:disabled~.b-input::after {
    border-color: rgba(255, 255, 255, 0.3);
}

.b-contain input~.b-input::after {
    display: block;
}

.b-contain input:checked~.b-input::after {
    display: block;
}



.b-contain input[type="checkbox"]:checked~.b-input {
    background: #3A87F0;
    border-color: #3A87F0;
}

.b-contain input[type="checkbox"]:disabled~.b-input {
    opacity: 0.5;
    cursor: not-allowed;
}